@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$vertical-gap: $horoscope-features-vertical-gap;
$height: #{$vertical-gap + ($horoscope-features-first-line-small-el-height * 2)};

.flowers {
  grid-area: flowers;
  width: 400px;
  height: 300px;
  display: flex;
  padding-top: 25px;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #1c573d;
  background: #fff;
  order: 3;

  &Title {
    $font-size: 24px;
    @include apply-font($font-size, $font-size);
    color: #3c3935;
  }

  &Content {
    display: flex;
    margin: auto 0;
    width: 100%;
    justify-content: space-around;

    &Asset {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      grid-row-gap: 15px;

      &Title {
        $font-size: 20px;
        @include apply-font($font-size, $font-size, 400);
        color: #bb5204;
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap-tablet;
  $height: $horoscope-features-second-third-line-el-height-tablet;

  .flowers {
    width: 350px;
    height: $height;
    margin: 0;

    &Content {
      width: 90%;
      margin: auto 0;

      &Asset {
        grid-row-gap: 25px;

        &Title {
          $font-size: 21px;
          @include apply-font($font-size, $font-size, 300);
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap;
  $height: $horoscope-features-forth-line-el-height-mobile;

  .flowers {
    width: 350px;
    height: 265px;
    padding-bottom: 10px;
    order: 2;

    &Content {
      width: 100%;

      &Asset {
        grid-row-gap: 10px;
        grid-template-rows: 100px auto !important;
        max-width: 100px !important;

        img {
          width: 100px;
        }
      }
    }
  }
}
