@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$horizontal-padding: 25px;
$wrapper-width: 820px;
$vertical-gap: $horoscope-features-vertical-gap;
$height: $horoscope-features-third-line-el-height;

.selfCare {
  grid-area: selfcare;
  width: $wrapper-width;
  height: $height;
  display: flex;
  padding: 10px $horizontal-padding 10px $horizontal-padding;
  flex-direction: column;
  align-items: center;
  border: 1px solid #1c573d;
  background: #fff;

  &Content {
    display: flex;
    margin-top: 18px;
    width: 100%;
    justify-content: space-around;

    &Article {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      grid-row-gap: 10px;
      min-width: 150px;

      &:first-child {
        span {
          max-width: 130px;
        }
      }

      img {
        min-width: 112px;
      }

      &Description {
        $font-size: 14px;
        $line-height: 1.31;
        $line-amount: 3;
        @include restrict-line-amount($font-size, $line-height, $line-amount);
        width: 100%;
        align-self: start;
        text-align: center;
        @include apply-font($font-size, $line-height, 400);
        color: #bb5204;
      }
    }
  }

  &Link {
    margin-top: auto;
    position: relative;

    &Title {
      $font-size: 13px;
      @include apply-font($font-size, $font-size, 600);
      color: #f18536;
      text-transform: uppercase;
      padding-right: 2px;
    }

    &Image {
      margin-left: 10px;
      height: 10px;
      width: 5px;
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $horizontal-padding: 65px;
  $wrapper-width: 350px;
  $vertical-gap: $horoscope-features-vertical-gap-tablet;

  .selfCare {
    width: $wrapper-width;
    height: auto;
    padding: 25px 30px 20px;

    &Content {
      margin-top: 30px;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      &Article {
        grid-row-gap: 20px;
        min-width: 110px;

        &Description {
          $font-size: 14px;
          $line-height: 1.29;
          $line-amount: 3;
          @include restrict-line-amount($font-size, $line-height, $line-amount);
          width: 100%;
          @include apply-font($font-size, $line-height, 300);
        }
      }
    }

    &Link {
      margin-top: 10px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $horizontal-padding: 25px;
  $wrapper-width: 350px;
  $vertical-gap: $horoscope-features-vertical-gap;

  .selfCare {
    width: $wrapper-width;
    height: auto;
    padding: 25px $horizontal-padding 20px;
    flex-wrap: nowrap;
    order: 6;

    &Title {
      margin-bottom: 10px;

      &Underline {
        width: 80px;
      }
    }

    &Content {
      flex-wrap: wrap;
      margin-top: 0;
      justify-content: space-around;
      flex-direction: row;

      &Article {
        margin-top: 25px;
        grid-row-gap: 8px;
        min-width: 120px;

        img {
          min-width: 112px;
        }

        &Description {
          $font-size: 14px;
          $line-height: 1.19;
          $line-amount: 3;
          @include restrict-line-amount($font-size, $line-height, $line-amount);
          @include apply-font($font-size, $line-height, 300);
        }
      }
    }

    &Link {
      &Title {
        $font-size: 13px;
        @include apply-font($font-size, $font-size, 600);
      }
    }
  }
}
