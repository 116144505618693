@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$vertical-gap: $horoscope-features-vertical-gap;

.wrapper {
  grid-area: sign-planet;
  background-color: #fff;
  border-radius: 20px;
  height: 300px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #1c573d;
}

.signElement {
  margin-top: 30px;
}

.signElement,
.rulingPlanet {
  width: 400px;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &Icon {
    $side-size: 60px;
    height: $side-size;
    width: $side-size;
    max-width: 100%;
    max-height: 100%;
    margin-right: 25px;
  }

  &Paragraph {
    max-width: 224px;

    &Title {
      @include apply-font(16px, 1.58, 400);
      color: #1c573d;
      font-family: Vollkorn, system-ui;

      &Bold {
        font-weight: 400;
      }
    }

    &Description {
      $line-amount: 3;
      $font-size: 13px;
      $line-height: 1.12;
      color: #1c573d;
      @include apply-font($font-size, $line-height, 400);
      @include restrict-line-amount($font-size, $line-height, $line-amount, false);
      margin: 0;
      height: auto;
    }
  }

  &Link {
    width: 100%;
    margin-right: auto;
    position: relative;
    font-size: 13px;
    color: #bb5204;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding-right: 10px;
    cursor: pointer;

    span {
      padding-right: 10px;
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap-tablet;

  .wrapper {
    width: 350px;
  }

  .signElement,
  .rulingPlanet {
    $icon-side-size: 63px;
    width: 100%;

    &Icon,
    picture {
      height: $icon-side-size;
    }

    &Icon {
      width: $icon-side-size;
    }

    &Paragraph {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      &Title {
        @include apply-font(18px, 1.2, 300);
        margin-bottom: 13px;

        &Bold {
          font-weight: 600;
        }
      }

      &Description {
        $line-amount: 2;
        $font-size: 17px;
        $line-height: 1.12;
        @include apply-font($font-size, $line-height, 300);
        @include restrict-line-amount($font-size, $line-height, $line-amount, false);
      }
    }

    &Link {
      margin-left: 0px;
      margin-top: 3px;
      margin-right: 10px;

      &Icon,
      picture {
        height: 27px;
        width: 15px;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap;

  .wrapper {
    width: 350px;
    height: 265px;
  }

  .signElement,
  .rulingPlanet {
    width: 350px;
    height: 100px;
    padding: 20px 18px;
    margin: 0 0 $vertical-gap 0;
    $icon-side-size: 55px;

    &Icon,
    picture {
      height: $icon-side-size;
    }

    &Icon {
      width: $icon-side-size;
      margin-left: 0;
    }

    &Paragraph {
      width: 220px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &Title {
        @include apply-font(16px, 1.2, 400);
        margin-bottom: 5px;

        &Bold {
          font-weight: 600;
        }
      }

      &Description {
        $line-amount: 3;
        $font-size: 13px;
        $line-height: 1.12;
        @include apply-font($font-size, $line-height, 400);
        @include restrict-line-amount($font-size, $line-height, $line-amount, false);
      }
    }

    &Link {
      margin-left: 15px;

      &Icon,
      picture {
        height: 22px;
        width: 11px;
      }

      &Icon {
        margin-top: 4px;
      }
    }
  }
}
