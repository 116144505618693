@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$dropdown-text-size: 21px;
$dropdown-icon-left-offset: 3%;
$dropdown-title-left-offset: 16px;
$sign-image-size: $horoscope-compatibility-select-image-size;

@mixin apply-dropdown-title($font-size: $dropdown-text-size) {
  @include apply-font($font-size, $font-size);
}

.otherSigns {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.otherSigns,
.otherSignsSection {
  width: 100%;
  height: 150px;
  padding: 25px 120px;
  background-color: #1C573D;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  &Title {
    $font-size: 32px;
    @include apply-font($font-size, 1);
    color: #fff;
    font-family: Vollkorn, system-ui;
  }

  &Button {
    cursor: pointer;
    @include apply-big-button(#fff, #F18536, normal, 700, $font-size: 16px, $height: 46px, $width: 117px);
    border-radius: 100px;
  }

  &Dropdown {
    position: relative;
    $button-height: 50px;

    &Button {
      border-radius: 10px;
      background-color: #fff;
      width: 220px;
      height: $button-height;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      outline: 0;
      border: 0;
      cursor: pointer;
      padding: 0 calc(13px + #{$dropdown-icon-left-offset}) 0 $dropdown-icon-left-offset;

      &Picture {
        margin-right: 5px;
        transform: rotate(90deg);
      }

      &Picture,
      &Image {
        width: 12px;
        height: 23px;
      }

      &Selected {
        margin-left: $dropdown-icon-left-offset;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;

        &Image,
        &Picture {
          width: $sign-image-size;
          height: $sign-image-size;
        }

        &Title {
          width: auto;
          margin-left: $dropdown-title-left-offset;
          color: #1C573D;
        }
      }

      &Placeholder {
        color: #02555d;
      }

      &Placeholder,
      &SelectedTitle {
        @include apply-dropdown-title();
        justify-content: center;
      }
    }

    &Menu {
      $vertical-option-offset: 7px;
      $signs-amount: 6;
      top: #{$button-height + 5px};
      max-height: calc(#{($vertical-option-offset * 2) * ($signs-amount)} + (#{$sign-image-size} * #{$signs-amount}));
      overflow-y: auto;
      margin: 0;
      color: #3c3935;
      z-index: 1;
      position: absolute;
      list-style-type: none;
      background-color: #fff;
      width: calc(100% - #{$dropdown-icon-left-offset * 2});
      left: $dropdown-icon-left-offset;
      padding: 0;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
      display: flex;
      flex-direction: column;
      align-items: center;

      &Option {
        width: 100%;
        padding: $vertical-option-offset 10px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        outline: 0;
        cursor: pointer;

        &Picture {
          width: $sign-image-size;
          height: $sign-image-size;
        }

        &Title {
          margin-left: $dropdown-title-left-offset;
          @include apply-dropdown-title();
        }
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $block-width: 100%;
  $dropdown-width: 185px;
  $button-width: 150px;
  $block-padding: 35px;
  $between-elements-gap: 26px;
  $button-height: 55px;
  $dropdown-title-left-offset: 10px;
  $dropdown-text-size: 18px;

  .otherSigns {
    width: $block-width;
    padding: $block-padding;

    &Title {
      line-height: 30px;
      font-size: 25px;
      max-width: 218px;
    }

    &Button {
      @include apply-big-button(
        #fff,
        #f18536,
        normal,
        700,
        $font-size: 16px,
        $height: $button-height,
        $width: $button-width
      );
      border-radius: 100px;
    }

    &Dropdown {
      position: relative;

      &Button {
        width: $dropdown-width;
        height: $button-height;
        padding-right: 15px;

        &Selected {
          margin-left: $dropdown-icon-left-offset;

          &Image,
          &Picture {
            width: $sign-image-size;
            height: $sign-image-size;
          }

          &Title {
            margin-left: $dropdown-title-left-offset;
            color: #3c3935;
          }
        }

        &Picture {
          transform: rotate(90deg);
        }

        &Image,
        &Picture {
          width: 10px;
          height: 19px;
        }

        &Placeholder {
          color: #02555d;
        }

        &Placeholder,
        &SelectedTitle {
          @include apply-dropdown-title($dropdown-text-size);
          justify-content: center;
        }
      }

      &Menu {
        &Option {
          &Title {
            margin-left: $dropdown-title-left-offset;
            @include apply-dropdown-title($dropdown-text-size);
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $block-width: 350px;
  $dropdown-width: 175px;
  $button-width: 117px;
  $block-padding: 15px;
  $between-elements-gap: 26px;
  $button-height: 46px;
  $dropdown-title-left-offset: 10px;
  $dropdown-text-size: 24px;
  $dropdown-icon-left-offset: 5%;

  .otherSigns {
    width: $block-width;
    padding: $block-padding;
    flex-wrap: wrap;

    &Title {
      max-width: unset;
      text-align: center;
      width: 100%;
      @include apply-font(18px, 26px);
      padding-bottom: 15px;
    }

    &Button {
      @include apply-big-button(
        #fff,
        #f78633,
        normal,
        400,
        $font-size: 16px,
        $height: $button-height,
        $width: $button-width,
        $isWithMedia: false,
      );
      border-radius: 100px;
    }

    &Dropdown {
      position: relative;

      &Button {
        width: $dropdown-width;
        height: $button-height;
        padding-right: 15px;

        &Selected {
          margin-left: $dropdown-icon-left-offset;

          &Image,
          &Picture {
            width: $sign-image-size;
            height: $sign-image-size;
          }

          &Title {
            margin-left: $dropdown-title-left-offset;
            color: #3c3935;
          }
        }

        &Picture {
          margin-right: 2px;
          transform: rotate(90deg);
        }

        &Image {
          margin-left: 2px;
        }

        &Image,
        &Picture {
          width: 9px;
          height: 17px;
        }

        &Placeholder {
          color: #02555d;
        }

        &Placeholder,
        &SelectedTitle {
          @include apply-dropdown-title($dropdown-text-size);
          justify-content: center;
        }
      }

      &Menu {
        &Option {
          &Title {
            margin-left: $dropdown-title-left-offset;
            @include apply-dropdown-title($dropdown-text-size);
          }
        }
      }
    }
  }
}
