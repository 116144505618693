@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$height: $horoscope-features-second-line-el-height;
$width: 820px;
$side-padding: 6px;
$content-width: #{$width - ($side-padding * 2)};

.gemstones {
  grid-area: gemstones;
  width: $width;
  height: $height;
  display: flex;
  padding: 25px $side-padding 0;
  flex-direction: column;
  align-items: center;
  border: 1px solid #1c573d;
  background: #fff;

  &Content {
    display: flex;
    max-width: $content-width;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: space-between;

    &Asset {
      width: calc(#{math.div($content-width, 5)});
      display: grid;
      grid-template-rows: 1fr auto;
      align-items: center;
      justify-items: center;
      padding-bottom: 15px;
      margin-right: 20px;
      padding-top: 30px;

      img {
        max-width: 90px;
      }

      &Text {
        display: flex;
        flex-direction: column;
        align-items: center;

        &Title {
          text-align: center;
          $font-size: 20px;
          @include apply-font($font-size, $font-size, 400);
          color: #bb5204;
          padding-top: 8px;
        }

        &Description {
          width: 90%;
          text-align: center;
          margin-top: 5px;
          @include apply-font(14px, 1.08);
          color: #bb5204;
          font-weight: 600;
          text-transform: lowercase;
        }
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap-tablet;
  $height: $horoscope-features-second-third-line-el-height-tablet;
  $width: 475px;
  $side-padding: 3px;
  $content-width: #{$width - ($side-padding * 2)};

  .gemstones {
    width: 350px;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;

    &Content {
      padding-bottom: 112px;
      max-width: $content-width;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &Asset {
        width: 100%;
        max-width: 130px;
        grid-template-rows: 100px auto;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        margin-right: 0px;

        img {
          max-width: 100%;
          height: auto;
          min-width: 100px;
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $horizontal-padding: 2px;
  $vertical-gap: $horoscope-features-vertical-gap;
  $width: 365px;
  $content-width: #{$width - ($side-padding * 2)};

  .gemstones {
    width: $width;
    height: 283px;    height: auto;
    padding: 25px $horizontal-padding 0;
    order: 5;

    &Content {
      margin-top: 10px;
      padding-bottom: 15px;
      max-width: $content-width;

      &Asset {
        margin-right: 0;

        &Text {
          &Description {
            margin-top: 15px;
            @include apply-font(16px, 1.08);
            font-weight: 600;
          }
        }
      }
    }
  }
}
