@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$vertical-gap: $horoscope-features-vertical-gap;
$height: auto;

.wrapper {
  display: flex;
}

.careers {
  grid-area: careers;
  width: 400px;

  &Content {
    &Record {
      margin-top: 12px;
    }
  }
}

.celebrity {
  grid-area: celebrity;
  width: 400px;

  &Content {
    &Record {
      margin-top: 5px;
    }
  }
}

.careers,
.celebrity {
  height: $height;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  border: 1px solid #1c573d;
  background: #fff;

  &Title {
    text-align: center;
    width: 80%;
    line-height: 32px;
  }

  &Content {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;

    &Record {
      $font-size: 16px;
      $line-height: 20px;
      text-align: center;
      @include apply-font($font-size, $line-height);
      color: #bb5204;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap-tablet;

  .wrapper {
    margin-bottom: $vertical-gap;
  }

  .careers,
  .celebrity {
    width: 350px;
    padding-bottom: 25px;
  }
}

@media (max-width: $mobile-view-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap;

  .wrapper {
    flex-direction: column;
  }

  .celebrity {
    &Content {
      &Record {
        margin-top: 3px;
      }
    }
  }
}
