@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.toolbar {
  $height: 35px;
  text-align: center;
  width: 100%;
  line-height: $height;
  display: grid;
  color: #1c573d;
  margin-bottom: 25px;

  &.common {
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-between;
  }

  .status {
    display: inline-flex;
    gap: 15px;

    &Item {
      display: flex;
      align-items: center;
    }

    &Text {
      padding-left: 2px;
      font-size: 15px;
      text-transform: capitalize;
    }

    &Indicator {
      $side-size: 15px;
      width: $side-size;
      height: $side-size;
      margin-right: 5px;
      flex-shrink: 0;
    }
  }

  .filter {
    $gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .sortBy,
    .filterBy {
      display: flex;
      align-items: center;
      gap: $gap;

      &Title {
        font-weight: 700;
        line-height: $height;
        font-size: 18px;
        cursor: pointer;
      }

      & > .icon {
        height: $height;
      }
    }

    .sortBy {
      $tick-padding: 30px;

      .drop {
        display: inline-block;
        position: relative;
        $vertical-padding: 13px;
        $border-width: 1px;
        $font-size: 18px;

        .button {
          position: relative;
          color: #1c573d;
          height: $height;
          line-height: $height;
          font-size: $font-size;
          cursor: pointer;

          & > .placeholder {
            font-weight: 700;
            line-height: $height;
            font-size: 18px;
          }

          &Tick {
            right: 12px;
            margin-top: -2px;

            &Top {
              margin-top: 4px;
            }
          }

          &:disabled {
            background-color: #eeeeee;
            color: #8a8787;
            cursor: default;
          }
        }

        .list {
          $padding: 10px;
          $font-size: 16px;
          position: absolute;
          list-style-type: none;
          top: $height + 15px;
          margin: 0;
          padding: 0;
          border-radius: 20px;
          background-color: #fff;
          border: 1px solid #bb5204;
          width: 300px;
          left: -($height + $gap + $tick-padding);
          line-height: 1.5;
          z-index: $header-z;
          color: #1c573d;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: $padding 15px;
            cursor: pointer;
            font-size: $font-size;

            &:first-child {
              border-radius: 20px 20px 5px 5px;
            }

            &Selected {
              background-color: #faf2ed;
              font-weight: 600;

              &Underline {
                text-decoration: underline;
              }
            }

            &Common {
              color: #1c573d;
              background-color: #fff;
            }

            &:last-child {
              border-radius: 5px 5px 20px 20px;
            }
          }
        }
      }

      .hidden {
        visibility: hidden;
        height: 0;
      }

      .circle {
        $side-size: 15px;
        display: inline-block;
        width: $side-size;
        height: $side-size;
        border: 2px solid #1c573d;
        border-radius: 50%;

        &Selected {
          background-color: #1c573d;
        }
      }

      &Selected {
        border: none;
        padding: 0 $tick-padding 0 0;
        display: flex;
        min-width: 0;
        font-size: 18px;
      }
    }

    .filterBy {
      $counter-side-size: 20px;
      position: relative;
      padding-left: 0;
      padding-right: $counter-side-size;

      &Title {
        color: #1c573d;
      }

      & > .counter {
        display: inline-block;
        background: #48ae7e;
        height: $counter-side-size;
        width: $counter-side-size;
        line-height: $counter-side-size;
        color: #fff;
        border-radius: 50%;
        font-size: 12;
        font-weight: 700;
      }

      & > .tick {
        top: calc(50% - 6px) !important;
        right: 0;
      }

      .tick {
        &Top {
          top: calc(50% - 2px) !important;
        }
      }
    }
  }

  .profileView {
    display: flex;
    align-items: center;
    gap: 25px;
    height: $height;
    justify-content: flex-end;
  }
}

.zeroResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;

  & > .title {
    color: #1c573d;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
  }

  /* stylelint-disable */
  & > .button {
    @include apply-big-button(#fff, #f18536, $font-size: 16px, $font-weight: 700);
    cursor: pointer;
  }
  /* stylelint-enable */
}

.panel {
  position: relative;
  border-top: 1px solid #5f5f5f;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #fff;
  z-index: $header-z;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  align-items: start;
  width: 100%;

  &Loading {
    height: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .category {
    $gap: 7px;
    $item-height: 35px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap;
    color: #1c573d;
    list-style-type: none;

    &Header {
      font-weight: 700;
      font-size: 18px;
      text-align: left;
      margin-bottom: 25px - $gap;
    }

    &Margin {
      margin-top: 15px;
    }

    &Item {
      border: 1px solid #000;
      border-radius: 8px;
      text-align: center;
      width: 100%;
      height: $item-height;

      .price {
        display: flex;
        gap: 5px;
        text-transform: lowercase;
        justify-content: center;
      }

      & > button {
        color: $primary-color-green;
        width: 100%;
        padding: 0;
        font-size: 16px;
        height: inherit;
        cursor: pointer;
      }

      &.selected {
        background-color: #faf2ed;

        & > button {
          line-height: 1;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          padding: 0;
          font-size: 16px;
        }
      }
    }

    & > .buttons {
      position: absolute;
      width: 100%;
      font-size: 16px;
      text-align: center;
      bottom: $item-height + $gap;
    }

    .buttons {
      & > .done {
        height: 55px;
        width: 100%;
        color: #fff;
        background-color: #f18536;
        border-radius: 10px;
        font-weight: 700;
        cursor: pointer;

        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }

      & > .clear {
        color: #5f5f5f;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: $common-tablet-max-width) {
  .panel {
    .category {
      &Item {
        & > button {
          font-size: 13px;
          line-height: 100%;
        }

        &.selected {
          & > button {
            font-size: 13px;
          }
        }
      }
    }
  }
}

/* Button in filter by price */
.priceFilterButton {
  * {
    pointer-events: none;
  }
}

@media (max-width: $mobile-view-max-width) {
  $height: 25px;

  .toolbar {
    justify-content: center;
    row-gap: 20px;
    margin: 0;

    &.common {
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "f p"
        "s s";
    }

    &.expanded {
      grid-template-areas:
        "f f"
        "p p"
        "s s";
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      justify-items: center;
    }

    .status {
      margin: 0 auto;
      grid-area: s;
    }

    .filter {
      grid-area: f;
      justify-content: flex-start;
      gap: 10px;

      .sortBy,
      .filterBy {
        gap: 5px;

        &Title {
          font-size: 15px;
        }

        & > .icon {
          height: $height;
        }
      }

      .filterBy {
        padding-right: 15px;

        & > .counter {
          $side-size: 15px;
          height: $side-size;
          width: $side-size;
          line-height: $side-size;
          font-size: 10;
        }
      }

      .sortBy {
        .drop {
          .button {
            font-size: 15px;

            .placeholder {
              font-size: 15px;
            }
          }

          .list {
            $padding: 10px;
            $font-size: 16px;
            left: -$height;
            width: 75vw;
          }
        }
      }
    }

    .profileView {
      grid-area: p;
      gap: 10px;
    }
  }
}
