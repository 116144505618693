@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  margin: 0 auto;
  max-width: 1320px;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 3px 6px 0px #00000029;
  padding: 30px;
}

.statistics, .firstStatistics {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 315px;
  align-items: center;
}

.title {
  font-family: Vollkorn, system-ui;

  &H2 {
    padding-top: 30px;
    padding-bottom: 12px;
    @include apply-font(32px, 1.2, 700);
    color: #1C573D;
  }
}

.paragraph {
  font-size: 18px;
  line-height: 27px;
  color:#1C573D;
  font-weight: 400;
}

.divider {
  height: 125px;
  width: 1px;
  background-color: #bb5204;
}

.tab {
  font-size: 21px;
  color: #1C573D;
  line-height: 26px;
  font-weight: 700;
  margin-top: 50px;
}

.pricingTitle {
  font-size: 32px;
  color: #BB5204;
  line-height: 39px;
  font-weight: 700;
  padding: 5px 0;
}

.statistics {

  .paragraph {
    font-size: 21px;
    color: #1C573D;
    line-height: 29px;
    font-weight: 700;
    max-width: 230px;
  }
}

.link {
  width: 173px;
  height: 53px;
  border-radius: 100px;
  background: #F18536;
  font-size: 18px;
  line-height: 53px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.textList {
  width: 100%;
  padding: 0px;
  margin: 0px;

  .starIcon {
    height: 20px;
    width: 20px;
    background-position: 0px 1300px;
    background-size: 20px;
    display: inline-block;
    margin: 0 5px -2px 5px;
  }

  .listItem {
    background: #FAF2ED;
    margin-top: 20px;
    min-height: 73px;
    overflow: hidden;

    .regularRate {
      padding-left: 20px;
      font-weight: 700;
      border: none;
      text-align: left;
    }

    .textValue {
      margin-top: 20px;
      display: block;
      border-right: 1px solid #BB5204;
      font-size: 18px;
      font-weight: 400;
      line-height: 33px;
      color: #1C573D;
      max-height: 33px;
      min-width: 25%;
    }
  }
}

.packages {
  display: flex;
  margin: 0px;
  padding: 0px;
  width: 100%;

  .first {
    align-self:self-end;

    .listItem {
      border-radius: 20px 0 0 20px;
      text-align: left;
      padding-left: 20px;

      .textValue {
        font-weight: 700;
        border: none;
      }
    }

    .tab, .link {
      display: none;
    }
  }

  .last {

    .listItem {
      border-radius: 0 20px 20px 0;

      .textValue {
        border: none;
      }
    }
  }

  .firstStatistics {

    .paragraph {
      max-width: 190px;
    }
  }
}

.sharedWrapper {
  &Margin {
    &Vertical {
      &None {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &Small {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
      }
    }
  }
}

@media (max-width: (1320px + 10px)) {
  .wrapper {
    width: 100%;
    padding: 0 20px;
    max-width: 100%;
  }

  .statistics, .firstStatistics {
    min-width: 25%;
  }

  .packages {
    width: 100%;
  }
}

@media (max-width: $mobile-view-max-width) {
  .title {
    &H2 {
      padding-top: 20px;
      @include apply-font(28px, 1.2, 600);
    }
  }

  .statistics, .firstStatistics {
    min-width: 100%;
  }

  .content {
    padding: 20px;
  }

  .tabList {
    margin-bottom: 10px;
    margin-top: 30px;
    border-radius: 10px;
    width: 100%;
    min-height: 46px;
    padding: 0px;
    background: #FAF2ED;
    display: flex;
    justify-content: space-between;

    .tabListButton {
      font-size: 16px;
      color: #1C573D;
      line-height: 46px;
      font-weight: 400;
      cursor: pointer;
      width: 100%;
      padding: 0 15px;
    }

    .tabListButtonSelected {
      background: #1C573D;
      border-radius: 10px;
      font-size: 16px;
      color: #fff;
      line-height: 46px;
      font-weight: 700;
      padding: 0 13px;
    }
  }

  .pricingTitle {
    text-align: center;
    margin: 0 auto;
  }

  .paragraph {
    text-align: left;
    margin: 0 auto;
  }

  .firstStatistics {

    .paragraph {
      max-width: 190px;
    }
  }

  .statistics {

    .paragraph {
      text-align: center;
    }

    .listItem {
      border-radius: 10px;
      text-align: center;
      padding-left: 0px;
      min-height: 131px;
      margin-top: 10px;

      .textValue {
        border: none;
      }

      .listTitle {
        font-size: 18px;
        color: #1C573D;
        line-height: 27px;
        font-weight: 700;
        display: block;
        padding: 10px 0 18px 0;
      }

      .listContent {
        font-size: 18px;
        color: #1C573D;
        line-height: 27px;
        font-weight: 400;
        display: block;
      }
    }

    .tab, .link {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .link {
      margin-bottom: 13px;
      height: 46px;
      width: 161px;
      line-height: 46px;
      font-size: 16px;
    }
  }
}
