@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: $header-z;
}

$left-content-width: $horoscope-desktop-left-section;
$right-content-width: $horoscope-desktop-right-section;

.horoscopesAndPredictions {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: $horoscope-tablet-max-width;

  &Content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0;

    &First {
      margin-bottom: 10px;
    }

    &First,
    &Second {
      $gap: 20px;
      width: $left-content-width + $right-content-width + $gap;
      justify-content: center;
      gap: 20px;
      display: flex;
      flex-direction: row;
    }

    &Left {
      margin: 30px 10px 30px 0;
      width: $left-content-width;
    }

    &Right {
      margin: 30px 0;
      width: $right-content-width;
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  .wrapper {
    padding: 25px $horoscope-tablet-horizontal-padding;
  }

  .horoscopesAndPredictions {
    &Content {
      flex-wrap: wrap;
      margin-top: 10px;
      justify-content: center;
      gap: 20px;

      &First,
      &Second {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        margin-bottom: 0;
        gap: 20px;
        width: $horoscope-desktop-left-section;
        justify-content: center;
      }

      &First {
        column-gap: 20px;
        margin-right: 0;
      }

      &Second {
        margin-bottom: 25px;
        justify-content: center;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding: 0;
  }

  .horoscopesAndPredictions {
    &Content {
      flex-wrap: wrap;
      margin-top: 0;
      justify-content: center;
      margin-bottom: 0px;

      &Left, &Right {
        margin-bottom: 0;
        width: 100vw;
      }

      &First,
      &Second {
        flex-direction: column;
        width: 100%;
        justify-content: center;
      }

      &First {
        margin-top: 0;
        justify-content: center;
        margin-right: 0;
      }

      &Second {
        margin-bottom: 20px;
        gap: 0px;
      }
    }
  }
}
