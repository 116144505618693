@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$vertical-gap: $horoscope-features-vertical-gap;
$height: $horoscope-features-second-line-el-height;

.traits {
  grid-area: traits;
  width: 400px;
  height: $height;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 20px;
  border: 1px solid #1c573d;
  background: #fff;

  &Container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Image {
      $side-size: 50px;
      height: $side-size;
      width: $side-size;
    }

    &Heading {
      margin-top: 20px;
    }

    &Content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &Record {
        margin-top: 15px;
        $font-size: 16px;
        @include apply-font($font-size, $font-size);
        color: #bb5204;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap-tablet;
  $height: $horoscope-features-second-third-line-el-height-tablet;

  .traits {
    width: 350px;
    height: $height;
    margin: 0;
  }
}

@media (max-width: $mobile-view-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap;

  .traits {
    width: 365px;
    height: 265px;

    &Container {
      display: flex;
      flex-direction: column;
      align-items: center;

      &Image {
        $side-size: 40px;
        height: $side-size;
        width: $side-size;
      }

      &Heading {
        font-size: 23px;
        margin: 15px 0 13px 0;

        &Underline {
          width: 70px;
        }
      }

      &Content {
        &Record {
          margin-top: 12px;
          $font-size: 15px;
          @include apply-font($font-size, $font-size);
        }
      }
    }
  }
}
